body {
  margin: 0;
  font-family: "Mukta", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  transition: 0.4s ease-in-out;
}

h1,
h2,
h3,
h4 {
  font-family: "Heebo", sans-serif;
}

h4,
h5 {
  font-weight: bold;
}

.header.shadow {
  box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13);
}

.header.hidden {
  transform: translateY(-110%);
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  body {
    height: 90vh;
  }
}
